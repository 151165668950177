body{
  font-size: 13px;
}

.ant-layout-header{
    background-color: #2E3D80;
    padding: 0;
}
.header .ant-menu.ant-menu-dark{
    background-color: #2E3D80;
    font-size: 13px;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title{
  font-size: 13px;
}
.ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub{
    background-color: #001529;
}
.ant-menu-inline-collapsed {
    width: 30px;
}

.trigger {
    font-size: 186px;
    padding: 0 24px;
    cursor: pointer;
    color: #ffffff;
  }

  
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    padding: 8px;
    font-size: 13px;
  }

  .ant-btn{
    font-size: 12px;
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
    height: 30px;
    line-height: 30px;
  }

  .address{
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 8px 12px;
    font-size: 13px;
  }

  .table-row-dark{
    background: #fafafa;
  }