.currentRoute{
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    margin: 5px;
    width: 100%;
    height: auto;
    align-items: center;
    min-width: 180px;
    /* max-width: 450px; */
}

.addRoute{
    justify-content: center;
    height: 40px;
    color: #e23232;
}

.routeAction{
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.removeRoute{
    position: absolute;
}

.driverDateOfBirth{
    background: white;
    opacity: 1;
    z-index: 10;
    transition: .5s;
}

.driver:hover .driverDateOfBirth{
    opacity: 0;
    transition: .5s;
    z-index: -1;
}