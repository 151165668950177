#login-layout .ant-layout-content{
    background-color: #03A9F4;
}
#sign-in-btn{
    background-color: #18244C;
    color: white;
    border-color:  #18244C;
}
#sign-up-btn{
    background-color: #03A9F4;
    color: white;
    border-color:  #03A9F4;
}
