.driver{
    display: flex;
    justify-content: space-between;
    margin: 5px;
    width: 100%;
    height: 45px;
    align-items: center;
    min-width: 180px;
}

.addDriver{
    justify-content: center;
    color: #e23232;
}

.driverAction{
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.removeDriver{
    position: absolute;
}

.driverDateOfBirth{
    background: white;
    opacity: 1;
    z-index: 10;
    transition: .5s;
    padding: 5px 0;
}

.driver:hover .driverDateOfBirth{
    opacity: 0;
    transition: .5s;
    z-index: -1;
}